import styled from 'styled-components';
import { ImgPatrocinio } from '../PublicacaoSelecionada/estilo';

const ImagemPatrocinio = styled(ImgPatrocinio)`
width:90%;
height: auto;

`;

export default ImagemPatrocinio;
